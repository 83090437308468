<template>
  <body class="hold-transition sidebar-mini layout-fixed sidebar-collapse">
    <div class="wrapper">
      <div v-if="isAuthenticated" class="mobile-bg">
        <Preloader ></Preloader>
        <Header> </Header>
        <router-view :user="this.user"  />
      </div>  
      <div v-if="!isAuthenticated">
        <router-view />
      </div>  
    </div>
  </body>
</template>

<script>
// import $ from 'jquery'
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css"


import VueJwtDecode from "vue-jwt-decode";
import Preloader from "./shared/Preloader.vue"

import Header from './shared/Header.vue';


export default {
  name: "App",
  // title : this.$route.meta.title,
  components: {
    Preloader,
    Header,
    // Navbar,
    // Sidebar,
    // Footer
  },
  data: function(){
    return {
      title : this.$route.params.title,
      user : {},
      isAuthenticated : false
    }
  },
  methods: {
    initialize() {
      let token = localStorage.getItem("token");

      if(token)
      {
        try{
          let decoded = VueJwtDecode.decode(token);
          this.user = decoded;
          this.isAuthenticated  = true;

          
        }catch(error)
        {
          console.log(error, 'An error has occured in Decoding Token');
        }
      }
          
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");

.mobile-bg {
    background : url('./assets/img/mobile-bg.png');
    height: 100vh;
    background-size: 102% 103% !important;
    background-repeat: repeat-y;
}
</style>
