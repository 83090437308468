<template >
    <div>
        <div class="col-lg-12 content-header">
            <div class="btn-group col-md-12" role="group" aria-label="Basic example">
                <button @click="goTo('dashboard')" type="button" class="btn btn-outline"><i class="fas fa-arrow-left 5x float-left"></i></button>
                <button type="button" class="btn btn-outline">Active Loans</button>
                <button type="button" class="btn btn-ouline"></button>
            </div>
            <div class="col-md-10"><h5 class="text-center"></h5></div>
        </div>
        <div class="col-md-12 content-body">
            <table class="table table-stripped table-bordered">
                <tr>
                    <th>#</th>
                    <th>Loan Type</th>
                    <th>Principal Amount</th>
                    <th>Balance</th>
                </tr>
                <tbody>
                    <tr v-for="loans in activeLoanDetails" :key="loans">
                        <td class="text-center">{{count + 1}}</td>
                        <td class="text-center">{{loans.loan_type}}</td>
                        <td class="text-right">{{$utils.numberWithCommas(loans.loan_amount.toFixed(2))}}</td>
                        <td class="text-right">{{$utils.numberWithCommas(loans.balance.toFixed(2))}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    
    props : ['user'],
    data(){
        return {
            activeLoanDetails : {},
            runningBalance : 0,
            count : 0
        }
    },
    methods: {
        getActiveLoans()
        {
            const self = this;
            const user = this.$props.user;
            console.log(user)
            let user_id = user.id;
            let accnt_no = user.acnt_no;
            let details = {
                fn : 'loadLoanDetails',
                body:{},
                params : {
                    user_id : user_id,
                    account_number : accnt_no
                }
            }

            this.$socket.emit('coop', details);
            this.$socket.on('loanDetails', (loanDetails) => {
                self.activeLoanDetails = loanDetails.details;
            })

        },
        goTo(name)
        {
            console.log(name)
            document.location = '/'+name
        },
        convertDate(date)
        {
            return this.$moment(date).format("DDMMMYY")
        },
        calculateBalance()
        {
            let balance = 0
         

            return this.$utils.numberWithCommas(parseFloat(balance).toFixed(2));
        }
    },

    mounted(){

       this.getActiveLoans();
    }
}
</script>
<style>
    .content-header{
        height: 40px;
        background : white;
        padding:0;
    }
    .content-body{
        background: rgba(255, 255, 255, 0.8);
    
    }
</style>