<template>
  <div>
    <img class="background" src="../assets/img/login_bg.jpg" >
        <div style="position: relative; top:10vh; opacity: .8;">
            <div class="col-lg-12 d-flex justify-content-center">
            <div class="login-box">
                <div class="card">
                    <div class="card-body login-card-body">
                        <div class="image">
                            <img src="../assets/img/logo.png" style="width: 100%; ">
                        </div>
                        <div class="form-group">
                            <label for="id_username">Username:</label> 
                            <input class="form-control" v-model="username" type="text" name="username" autofocus="" autocomplete="off"
                            placeholder="Username">
                        </div>
                        <div class="form-group">

                            <label for="frm-login-pass">Password:</label>
                            <div class="input-group">
                                <input class="form-control" v-model="pass" autocomplete="off" type="password" id="pass" name="pass" placeholder="Password">
                                <!-- <div class="input-group-append">
                                    <button class="btn btn-outline-secondary"  type="button">
                                        <i class="fa fa-eye"></i> 
                                    </button>
                                </div> -->
                            </div>
                        </div>
                        <input @click="login()" class="btn btn-primary btn-block" type="submit" value="Login" />
                        <input @click="register()" class="btn btn-danger btn-block" type="button" value="Register" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
    export default {
        data()
        {
            return{
                username : '',
                pass : '',
                isValidated : false,
                token : '',
                socketID : ''
            }
        },
        props:['user'],
        methods: {
            register()
            {
                document.location = "/register"
            },
            async login()
            {
                let loginDetails = {
                    fn: 'authenticate',
                    body : {
                        username : this.username,
                        password : this.pass
                    },
                    params : {
                        authCode : null,
                        socketID : this.socketID
                    }
                }

                this.$socket.emit('auth', loginDetails);
                this.$socket.on('isAuthenticated', (result) => {
                    localStorage.setItem('token', result.token);
                    document.location = '/dashboard';
                })
                this.$socket.on('authenticateError', (result) => {
                    this.$swal.fire({
                        title: 'AUTHENTICATION Error',
                        text: result.msg,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                })
                this.$socket.on('authCodeConfirmation', (result) => {
                    this.$swal.fire({
                        title : result.title,
                        html : result.msg,
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText:
                            'Submit',
                        cancelButtonText:
                            'Cancel',
                    }).then((response) => {
                        if(response.isConfirmed){
                            let authCode = document.getElementById("auth_code").value;
                            loginDetails.params.authCode = authCode;
                            this.$socket.emit('auth', loginDetails)
                        }
                    })
                })


                
            }
        },
        created(){
            if(localStorage.getItem('token'))
            {
                this.$router.push({name: 'Dashboard'})
            }
            this.$socket.on('socketID',(socketID) => {
                this.socketID = socketID;
            })
        }
    };
</script>
<style>
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
.is-invalid{
    border-color: #dc3545 !important;
}

.image > span {
    position: absolute;
    left: 0;
    top : 85px;
    width: 100%;
    font-weight: 700;
    font-size: 3rem;
    text-align: center;
    color: #fff;
    text-shadow: #282c34;
    -webkit-text-stroke: 1px #000;
}

.background{
    position: fixed;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

</style>