<template >
    <div>
        <div class="col-lg-12 content-header">
            <div class="btn-group col-md-12" role="group" aria-label="Basic example">
                <button @click="goTo('dashboard')" type="button" class="btn btn-outline"><i class="fas fa-arrow-left 5x float-left"></i></button>
                <button type="button" class="btn btn-outline">Share Capital Ledger</button>
                <button type="button" class="btn btn-ouline"></button>
            </div>
            <div class="col-md-10"><h5 class="text-center"></h5></div>
        </div>
        <div class="col-md-12 content-body">
            <table class="table table-stripped table-bordered table-responsive">
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Transaction</th>
                    <th>Amount</th>
                    <th>Balance</th>
                </tr>
                <tbody>
                    <tr v-for="(shareCap, index) in shareCapitalDetails" :key="shareCap.cft_id">
                        <td class="text-center">{{index + 1}}</td>
                        <td class="text-center">{{convertDate(shareCap.transDate)}}</td>
                        <td class="text-center">{{shareCap.shortCode}}</td>
                        <td class="text-right">{{$utils.numberWithCommas(shareCap.amount.toFixed(2))}}</td>
                        <td class="text-right">{{$utils.numberWithCommas(shareCap.balance.toFixed(2))}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    
    props : ['user'],
    data(){
        return {
            shareCapitalDetails : {},
            runningBalance : 0
        }
    },
    methods: {
        getShareCapital()
        {
            let requestDetails = {
                fn : 'getShareCapitalDetails',
                body : {
                    account_number : this.$props.user.acnt_no
                },
                params : {}
            }

            this.$socket.emit('coop', requestDetails)
            this.$socket.on('shareCapitalDetails', result => {
                
                this.shareCapitalDetails = result.details
                console.log(result.details)
            })
        },
        goTo(name)
        {
            document.location = '/'+name
        },
        convertDate(date)
        {
            return this.$moment(date).format("DDMMMYY")
        },
        calculateBalance(amount, transType, count)
        {
            let balance = 0
            if(count==1)
            {
                balance = amount;
                this.runningBalance = balance
            }else{
               if(transType=="WDRW")
               {
                    balance = this.runningBalance
                    balance -= parseFloat(amount)
                    this.runningBalance = balance;
               }else{
                    balance = this.runningBalance
                    balance += parseFloat(amount)
                    this.runningBalance = balance;
               }
            }

            return this.$utils.numberWithCommas(parseFloat(balance).toFixed(2));
        }
    },

    mounted(){

        this.getShareCapital();
    }
}
</script>
<style>
    .content-header{
        height: 40px;
        background : white;
        padding:0;
    }
    .content-body{
        background: rgba(255, 255, 255, 0.8);
    
    }
</style>