<template>
    <div class="widget-user-header">
      <img class="edashboard-header" src="../assets/img/paceman_header.jpg" alt="Avatar">
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>