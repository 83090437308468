  <template>
<div>
    <div class="col-md-12">
      <div class="bg-profile">
        <div class="widget-user-image text-center">
          <img class="img-circle" style="width:30%;" src="../assets/img/blank.png" alt="Avatar">
          <h3 class="widget-user-username">{{name}}</h3>
          <h5 class="widget-user-desc">{{position}}</h5>
        </div>
      </div>
      <div class="card card-widget widget-user">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6 border-right">
              <div @click="goTo('shareCap')" class="description-block pointer">
                <h5 class="description-header">{{share_capital==0?'...':$utils.numberWithCommas(share_capital.toFixed(2))}}</h5>
                <span class="description-text">SHARE CAPITAL</span><br />
                <small>Tap for more info</small>
              </div>
              <!-- /.description-block -->
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <div  @click="goTo('activeLoans')" class="description-block pointer">
                <h5 class="description-header">{{loan_balance==0?'...':$utils.numberWithCommas(loan_balance.toFixed(2))}}</h5>
                <span class="description-text">LOAN BALANCE</span><br />
                <small>Tap for more info</small>
              </div>
              <!-- /.description-block -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
      </div>
      <!-- /.widget-user -->
    </div>
    <div class="col-md-12">
      <a href="#" class="btn btn-app bg-info col-sm-4">
        <i class="fas fa-money-bill"></i> Loan Application
      </a>
      <a href="#" class="btn btn-app bg-warning col-sm-4">
        <i class="fas fa-file"></i> Apply for Leave
      </a>
      <a href="#" @click="goTo('Payslip')" class="btn btn-app bg-secondary col-sm-4">
        <i class="fas fa-table"></i> My E-Payslip
      </a>
    </div>

    <div class="mobile-footer">
      <button @click="logOut()" type="button" class="btn btn-danger btn-block"><i class="fas fa-sign-out-alt"></i> Logout</button>
    </div>
</div>
</template>

<script>
export default {

  props : ['user'],
  data(){
    return{
      user_id : 0,
      name : '',
      position : '',
      loan_balance : 0,
      share_capital : 0,
      account_number : '',
      employee_id : '',
      socketID : ''
    }
  },
  methods:{
   
    totalShareCap()
    {
      this.$socket.emit('coop', {fn:'getTotalShareCapital', body:{user_id:this.user_id, account_number: this.account_number}, params:{}});
      this.$socket.on('totalShareCap', (totalShareCapital) => {
        this.share_capital = totalShareCapital.details
      })

    },
    totalLoanBalance()
    {
            const self = this;
            const user = this.$props.user;
            console.log(user)
            let user_id = user.id;
            let accnt_no = user.acnt_no;
            let details = {
                fn : 'loadLoanDetails',
                body:{},
                params : {
                    user_id : user_id,
                    account_number : accnt_no
                }
            }

            this.$socket.emit('coop', details);
            this.$socket.on('loanDetails', (loanDetails) => {
                self.activeLoanDetails = loanDetails.details;
                for(let ld in self.activeLoanDetails)
                {
                  self.loan_balance += self.activeLoanDetails[ld].balance;
                }
            })
    },

    logOut()
    {
      this.isAuthenticated = false;
      localStorage.removeItem("token");
      this.$router.push({name: 'Login'})
    },
    goTo(name)
    {
      document.location='/'+name
    }
  },
  created(){

    
     let user = this.$props.user;
     this.user_id = user.id
     this.name = user.name
     this.position = user.position
     this.account_number = user.acnt_no
     this.employee_id = user.emp_id
     this.totalShareCap();
     this.totalLoanBalance();

  }
}
</script>

<style>
  .bg-profile{
      background-color : none;
      color : white;
  }

  .edashboard-header{
      width: 100%;
  }

  .col-sm-6 {
      width: 50%;
  }

  .col-sm-4 {
      width: 30%;
  }

  .mobile-bg {
      background : url('../assets/img/mobile-bg.png') repeat-y;
      height: 100vh;
      background-size: 102% 103% !important;
  }

  .widget-user-image {
      margin : 15px;
  }

  .mobile-footer {
      position: absolute;
      bottom: 0;
      width: 100%;

  }
</style>