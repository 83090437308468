import axios from "axios";
const node_env = process.env.NODE_ENV
// export default axios.create({
//   baseURL: "http://localhost:4201/",
//   headers: {
//     // "Content-type": "application/json"
//     Authorization : 'Bearer ' + localStorage.getItem('token')
//   }
// });
if(node_env=="development"){
    axios.defaults.baseURL = 'http://localhost:4201/';
}else{
    axios.defaults.baseURL = 'https://api.pacemanmpc.com/';
}

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');