import { createRouter, createWebHistory } from 'vue-router'

import dashboard from '../views/DashboardView';
import login from '../views/LoginView';
import register from '../views/RegisterView';
import shareCapital from '../views/ShareCapitalDetailsView';
import ActiveLoans from '../views/ActiveLoanDetailsView';
import EPayslip from '../views/EPayslipView';

import $ from 'jquery'

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: dashboard,
    meta : {
      title : 'Dashboard'
    }
  },
  {
    path: '/',
    name: 'Login',
    component: login,
    meta : {
      title : 'Login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: register,
    meta : {
      title : 'register'
    }
  },
  {
    path: '/shareCap',
    name: 'ShareCaptial',
    component: shareCapital,
    meta : {
      title : 'Share Capital'
    }
  },
  {
    path: '/activeLoans',
    name: 'ActiveLoans',
    component: ActiveLoans,
    meta : {
      title : 'Active Loans'
    }
  },
  {
    path: '/payslip/',
    name: 'Payslip',
    component: EPayslip,
    meta : {
        title : 'Payslip'
    }
},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  let title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Dashboard';
  window.document.title = title
  $('#headerTitle').html(title)
  next();
})

export default router
