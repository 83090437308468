<template>
  <div>
    <img class="background" src="../assets/img/login_bg.jpg" >
        <div style="position: relative; top:5vh; opacity: .8;">
            <div class="col-lg-12 d-flex justify-content-center">
            <div class="login-box">
                <div class="card">
                    <div class="card-body login-card-body">
                        <div class="image">
                            <img src="../assets/img/logo.png" style="width: 100%; ">
                        </div>
                        <div class="form-group">
                            <label for="id_username">Employee ID:</label> 
                            <input v-model="employee_id" class="form-control" type="text" name="username" autofocus="" autocomplete="off" 
                            placeholder="Employee ID" >
                            <div v-if="msg.employee_id" class="text-danger">
                                An employee ID is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="id_username">Username:</label> 
                            <input v-model="username" class="form-control" type="text" name="username" autofocus="" autocomplete="off" formControlName="username" 
                            placeholder="Username">
                            <div v-if="msg.username" class="text-danger">
                                {{msg.username}}
                            </div>
                        </div>
                        <div class="form-group">

                            <label for="frm-login-pass">Password:</label>
                            <div class="input-group">
                                <input v-model="pass" class="form-control" autocomplete="off"
                                type="password"
                                id="pass" name="pass" placeholder="Password">
                            </div>
                            
                            <div v-if="msg.password" class="text-danger">
                                {{msg.password}}
                            </div>
                        </div>
                        <div class="form-group">

                            <label for="frm-login-pass">Confirm Password:</label>
                            <div class="input-group">
                                <input v-model="confirmPass" class="form-control" autocomplete="off"
                                type="password"
                                id="confirmPassword" name="confirmPassword" placeholder="Confirm Password">
                             
                            </div>
                            
                            <div v-if="msg.confirmPass" class="text-danger">
                                {{msg.confirmPass}}
                            </div>
                        </div>
                        <input v-if="!isValidated" class="btn btn-success btn-block" disabled type="submit" value="Submit" />
                        <input v-if="isValidated" class="btn btn-success btn-block" @click="submitRegistration()" type="submit" value="Submit" />
                        <input class="btn btn-danger btn-block" @click="cancelRegistration()" type="button" value="Cancel" />
                
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data()
    {
        return{
            employee_id : '',
            username : '',
            pass : '',
            confirmPass : '',
            msg: [],
            isValidated : false, 
            socketID : ''
        }
    },

    methods : {
        cancelRegistration(){
            document.location = '/'
        },
        validateEmployeeID(value)
        {
            if(value=="")
            {
                this.msg.employee_id = "An employee ID is required"
            }else{
                this.msg.employee_id = ""
            }
        },
        validateUsername(value)
        {
            if(value.length < 5){
                this.msg.username = "Username must have at least 5 characters";
            }else{
                this.msg.username = ""
            }
        },
        validatePassword(value)
        {
            if(value.length < 6){
                this.msg.password = "Password must have at least 6 characters";
            }else{
                this.msg.password = ""
            }
        },
        validateConfirmPass(value)
        {
            if(this.pass !== value)
            {
                this.msg.confirmPass = "Password does not match!";
            }else{
                this.msg.confirmPass = ""
                this.isValidated = true;
            }
        },

        submitRegistration(){
            
            let emitDetails = {
                fn: 'register',
                body : {
                    employee_id : this.employee_id,
                    username : this.username,
                    password : this.pass,
                },
                params : {
                    socketID : this.socketID
                }
            }

            this.$socket.emit("auth", emitDetails);
            this.$socket.on("registrationError",(details) =>{
                this.$swal.fire({
                    title: 'REGISTRATION Error',
                    text: details.msg,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            })
            this.$socket.on("registrationSuccess",(details) =>{
                this.$swal.fire({
                    title: 'REGISTRATION Successful',
                    text: details.msg,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK'
                }).then((result)=>{
                     if (result.isConfirmed) {
                            document.location = '/'
                        }
                })
            })


        }
    },
    created(){
        this.$socket.on('socketID',(socketID) => {
            this.socketID = socketID;
        })
    },

    watch : {
        employee_id(value){
            this.employee_id = value;
            this.validateEmployeeID(value)
        },
        username(value){
            this.username = value;
            this.validateUsername(value)
        }, 
        pass(value){
            this.pass = value;
            this.validatePassword(value)
        },
        confirmPass(value){
            this.confirmPass = value
            this.validateConfirmPass(value);
        }
    }
}
</script>
<style>
    
    .invalid-feedback {
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545;
    }
    .is-invalid{
        border-color: #dc3545 !important;
    }

    .image > span {
        position: absolute;
        left: 0;
        top : 85px;
        width: 100%;
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        color: #fff;
        text-shadow: #282c34;
        -webkit-text-stroke: 1px #000;
    }

    .background{
        position: fixed;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
    }
</style>