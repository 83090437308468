<template>
    <div>
      <div class="col-lg-12 content-header">
          <div class="btn-group col-md-12" role="group" aria-label="Basic example">
              <button @click="goTo('dashboard')" type="button" class="btn btn-outline"><i class="fas fa-arrow-left 5x float-left"></i></button>
              <button type="button" class="btn btn-outline">My E-Payslip</button>
              <button type="button" class="btn btn-ouline"></button>
          </div>
          <div class="col-md-10"><h5 class="text-center"></h5></div>
      </div>
      <div class="col-md-12 content-body">
        <div class="col-md-12 pt-2 pb-2 clearfix">
            <select @change="getPaySlip()" v-model="selectedPP" name="payrollPeriod" class="form-control text-center " id="payrollPeriod">

              <option disabled value="" selected>Please Select a Payroll Period</option>
              <option  v-for="pp in payrollPeriod" :key="pp.id" :value="pp.per_id">{{pp.per_from}} - {{pp.per_to}}</option>
            </select>

          <div v-if="!loading" class="col-md-12">
            <table class="table table-bordered">
              <tr>
                <th class="text-left bg-info">GROSS PAY</th>
                <th class="text-right">{{$utils.numberWithCommas(parseFloat(this.payslipData.gross_pay).toFixed(2))}}</th>
              </tr>
              <tr>
                <th class="text-left bg-danger">MANDATED DEDUCTION</th>
                <th class="text-right">{{$utils.numberWithCommas(parseFloat(this.totalStatBen).toFixed(2))}}</th>
              </tr>
              <tr>
                <th class="text-left bg-success">NET PAY</th>
                <th class="text-right">{{$utils.numberWithCommas(parseFloat(this.netPay).toFixed(2))}}</th>
              </tr>
            </table>
            <table class="table table-stripped table-bordered">
              <tr>
                <th colspan="3" class="text-center bg-info">Gross Pay Details</th>
              </tr>
              <tr class="bg-success">
                <th>Description</th>
                <th>Hours</th>
                <th>Pay</th>
              </tr>
              <tr v-for="(mh, index) in grossPayDetails" :key="index">
                <td>{{mh.desc}}</td>
                <td>{{mh.value}}</td>
                <td class="text-right">{{$utils.numberWithCommas(mh.payment.toFixed(2))}}</td>
              </tr>
              <tr>
                <td colspan="2">GROSS PAY</td>
                <th class="text-right">{{$utils.numberWithCommas(parseFloat(this.payslipData.gross_pay).toFixed(2))}}</th>
              </tr>
            </table>
            <table class="table table-stripped table-bordered">
              <tr>
                <th colspan="2" class="text-center bg-danger">Mandated Deduction</th>
              </tr>
              <tr>
                <td>SSS</td>
                <td class="text-right">{{$utils.numberWithCommas(parseFloat(this.payslipData.sss).toFixed(2))}}</td>
              </tr>
              <tr>
                <td>PHILHEALTH</td>
                <td class="text-right">{{$utils.numberWithCommas(parseFloat(this.payslipData.philhealth).toFixed(2))}}</td>
              </tr>
              <tr>
                <td>PAG-IBIG</td>
                <td class="text-right">{{$utils.numberWithCommas(parseFloat(this.payslipData.pag_ibig).toFixed(2))}}</td>
              </tr>
              <tr v-if="payslipData.sss_loan!='0.00'">
                <td>SSS Loan</td>
                <td class="text-right">{{$utils.numberWithCommas(parseFloat(this.payslipData.sss_loan).toFixed(2))}}</td>
                
              </tr>
            </table>
          </div>
          <div v-if="loading && showLoading" class="col-md-12 text-center" >
              ... Fetching Payslip Details ...
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    props : ['user'],
    data(){
        return {
            loading : true,
            showLoading : false,
            payrollPeriod : [],
            payslipData : {},
            grossPayDetails : {},
            totalStatBen : 0,
            netPay : 0,
            selectedPP:0,
        }
    },
    methods: {

        async getPaySlip()
        {
          let employee_id = this.$props.user.emp_id
          let requestDetails = {
            fn : 'getIndividualPayslip',
            body : {},
            params : {employee_id : employee_id, pp_id : this.selectedPP}
          }

          if(this.selectedPP!=0)
          {
            this.showLoading = true;
            this.$socket.emit('payroll', requestDetails)
            this.$socket.on('payslipData', result => {
                
                this.payslipData = result.details
                this.loading = false;
                this.showLoading = false;
                this.grossPayDetails = this.payslipData.manhours;
  
                this.totalStatBen = this.payslipData.sss + this.payslipData.philhealth + this.payslipData.pag_ibig + this.payslipData.sss_loan;
                this.netPay = parseFloat(this.payslipData.gross_pay) - parseFloat(this.totalStatBen);
  
  
                console.log(this.payslipData)
                
            })
          }
          
        },
      
        goTo(name)
        {
            document.location = '/'+name
        },


        getPayrollPeriod()
        {
            let requestDetails = {
                fn : 'getPayrollPeriod',
                body : {},
                params : {}
            }

            this.$socket.emit('payroll', requestDetails)
            this.$socket.on('payrollPeriod', result => {
                
                this.payrollPeriod = result.details
                
            })
        },
    },
    mounted() {
      this.getPayrollPeriod()
    },
}
</script>
<style lang="">
    
</style>