import { createApp } from 'vue'
import App from './App.vue'
window.$ = window.jQuery = require("jquery");


import jq from 'jquery';
import axios from 'axios';
import './http-common';
import swal2 from 'sweetalert2';
import moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';
import io from 'socket.io-client';

import {utils} from "./utils";

//import adminlte scripts
import "../node_modules/admin-lte/dist/js/adminlte.min.js"
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js"
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js"
import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js"
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js"


//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css"
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css"
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css"
import '@vuepic/vue-datepicker/dist/main.css'
import router from './router'
const node_env = process.env.NODE_ENV


library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch()

let app = createApp(App)
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$http = axios;
app.config.globalProperties.$jq = jq;
app.config.globalProperties.$swal = swal2;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$socket = io((node_env=="development"?"ws://localhost:4201":"wss://api.pacemanmpc.com/"),{
    extraHeaders : {
        "headercheck" : "JesusReigns7x"
    }
});

app.use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
